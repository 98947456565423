// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["zVcud7j2n", "aBct6ilVw", "J_8zLhaZl"];

const variantClassNames = {aBct6ilVw: "framer-v-2c74ch", J_8zLhaZl: "framer-v-1l354iu", zVcud7j2n: "framer-v-1tguxmc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "zVcud7j2n", "Tab 2": "aBct6ilVw", "Tab 3": "J_8zLhaZl"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "zVcud7j2n", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zVcud7j2n", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap1ggg4px = activeVariantCallback(async (...args) => {
setVariant("zVcud7j2n")
})

const tap198lxzo = activeVariantCallback(async (...args) => {
setVariant("aBct6ilVw")
})

const tap1u1d4ep = activeVariantCallback(async (...args) => {
setVariant("J_8zLhaZl")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-EPTSg", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1tguxmc", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"zVcud7j2n"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({aBct6ilVw: {"data-framer-name": "Tab 2"}, J_8zLhaZl: {"data-framer-name": "Tab 3"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1g0fipw-container"} layoutDependency={layoutDependency} layoutId={"WNFA8WSCy-container"} transition={transition}><Tab height={"100%"} id={"WNFA8WSCy"} layoutId={"WNFA8WSCy"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({aBct6ilVw: {tap: tap1ggg4px, variant: "ym0P2kopY"}, J_8zLhaZl: {tap: tap1ggg4px, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-11r0qv-container"} layoutDependency={layoutDependency} layoutId={"XteSbhmr1-container"} transition={transition}><Tab height={"100%"} id={"XteSbhmr1"} layoutId={"XteSbhmr1"} tap={tap198lxzo} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({aBct6ilVw: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-163hxo8-container"} layoutDependency={layoutDependency} layoutId={"h9hhnXayT-container"} transition={transition}><Tab height={"100%"} id={"h9hhnXayT"} layoutId={"h9hhnXayT"} tap={tap1u1d4ep} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({J_8zLhaZl: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-EPTSg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EPTSg * { box-sizing: border-box; }", ".framer-EPTSg .framer-7y8bsw { display: block; }", ".framer-EPTSg .framer-1tguxmc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-EPTSg .framer-1g0fipw-container, .framer-EPTSg .framer-11r0qv-container, .framer-EPTSg .framer-163hxo8-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-EPTSg .framer-1tguxmc { gap: 0px; } .framer-EPTSg .framer-1tguxmc > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-EPTSg .framer-1tguxmc > :first-child { margin-left: 0px; } .framer-EPTSg .framer-1tguxmc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"aBct6ilVw":{"layout":["auto","auto"]},"J_8zLhaZl":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const FramerpFnHRq7Lw: React.ComponentType<Props> = withCSS(Component, css, "framer-EPTSg") as typeof Component;
export default FramerpFnHRq7Lw;

FramerpFnHRq7Lw.displayName = "Elements/Tabs Copy";

FramerpFnHRq7Lw.defaultProps = {height: 49, width: 275};

addPropertyControls(FramerpFnHRq7Lw, {variant: {options: ["zVcud7j2n", "aBct6ilVw", "J_8zLhaZl"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(FramerpFnHRq7Lw, [...TabFonts])